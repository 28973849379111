import "./App.css";
import Main from "./components/NewMain";
import Nav from "./components/Nav";
import AOS from "aos";
// import "aos/dist/aos.css"; // You can also use <link> for styles
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="App">
      <Nav />
      <Main />
    </div>
  );
}

export default App;
