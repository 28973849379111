import "./simpleintro.scss";
import profilePic from "../resources/me.JPEG";

const Intro = () => {
  return (
    <div className="header-container">
      <div className="header-pic">
        <img src={profilePic} className="profpic" />
      </div>
      <div className="header-text">
        <div>SURYA RAMESH</div>
        <div className="subheading">Full Stack Software Developer</div>
      </div>
    </div>
  );
};

export default Intro;
