// import "./header.css";
import profilePic from "../resources/me.JPEG";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { useEffect, useRef, useState } from "react";
// import Bear from "../resources/bear.svg";
// import { useSpring, animated } from "react-spring";
// import { BearOutline, BearUpper, BearLower } from "./BearParts";
import Bear from "./Bear";
import Intro from "./Intro";
import Education from "./Education";
import Experience from "./Experience";
import Projects from "./Projects";

const Header = () => {
  //   const ref = useRef();
  // useEffect(() => {
  //   if (ref.current) console.log(ref.curent.offset);
  // }, [ref.current]);

  return (
    <div className="main-container">
      <Intro />
      <Experience />
      <Education />
      <Projects />
    </div>
  );
};

export default Header;
