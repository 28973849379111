import "./projects.scss";
import Bear from "./Bear";
import { useState, useEffect } from "react";

const Projects = () => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    // `setImmediate` is roughly equal to `setTimeout(() => { ... }, 0)
    // Using `setToggle` without `setImmediate` breaks the animation
    // as we first need to allow for the measurement of the `path`
    // lengths using `.getTotalLength()`
    // setImmediate(() => {
    //   setToggle(true);
    // });
    setTimeout(() => {
      setToggle(true);
    }, 0);
  }, []);

  return (
    <div className="proj-container">
      <div style={{ width: "100%" }}>
        <div className="heading-title">Projects</div>
        <div className="projects-container">
          <div className="project-card" data-aos="zoom-in">
            <h3 className="card-title">Secure File Sharing Server</h3>
            <div className="card-content">
              Designed a file sharing application in Golang capable of detecting
              malicious attackers and preserving file contents while modifying a
              file and sharing it with others
            </div>
          </div>
          <div className="project-card" data-aos="zoom-in">
            <h3 className="card-title">Aneta</h3>
            <div className="card-content">
              Semantic Search Engine on custom personal data.
            </div>
          </div>
          <div className="project-card" data-aos="zoom-in">
            <h3 className="card-title">Placeholder 1</h3>
            <div className="card-content">Lorem Ipsum Dolor</div>
          </div>
          <div className="project-card" data-aos="zoom-in">
            <h3 className="card-title">Placeholder 2</h3>
            <div className="card-content">Lorem Ipsum Dolor</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
