import "./nav.css";
import { useState } from "react";

const Nav = () => {
  const [mobile, setMobile] = useState(false);
  return (
    <ul className="navbarContainer">
      {mobile ? (
        <div className="navMobile">
          <li className="navList " style={{ float: "left" }}>
            <a className="active" href="#home">
              Surya Ramesh
            </a>
          </li>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>{" "}
          </button>
        </div>
      ) : (
        <div className="navMobile">
          <li className="navList " style={{ float: "left" }}>
            <a className="active" href="">
              Surya Ramesh
            </a>
          </li>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>{" "}
          </button>
          <li className="navList" id="navHideLi">
            <a href="#contact">Contact</a>
          </li>
          <li className="navList" id="navHideLi">
            <a href="#projects">Projects</a>
          </li>
          <li className="navList" id="navHideLi">
            <a href="#experience">Experience</a>
          </li>
          <li className="navList" id="navHideLi">
            <a href="#education">Education</a>
          </li>
          <li className="navList" id="navHideLi">
            <a href="#about">About</a>
          </li>
        </div>
      )}
    </ul>
  );
};
export default Nav;
