import { BearOutline, BearUpper, BearLower } from "./BearParts";

const Bear = ({ toggle, backgroundColor = "#f0f0f0" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="303.8095238095238"
      fill="none"
      viewBox="0, 0, 400,303.8095238095238"
    >
      <BearUpper
        toggle={toggle}
        delaytime={3000}
        duration_stroke={4000}
        duration_fill={500}
        delay_fill={6000}
        backgroundColor={backgroundColor}
      />
      <BearLower
        toggle={toggle}
        delaytime={7000}
        duration_stroke={8000}
        duration_fill={500}
        delay_fill={8500}
        backgroundColor={backgroundColor}
      />
      <BearOutline
        toggle={toggle}
        delaytime={0}
        duration_stroke={6000}
        duration_fill={0}
        backgroundColor={backgroundColor}
      />
    </svg>
  );
};

export default Bear;
