import "./education.css";
import Bear from "./Bear";
import { useState, useEffect } from "react";

const Education = () => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    // `setImmediate` is roughly equal to `setTimeout(() => { ... }, 0)
    // Using `setToggle` without `setImmediate` breaks the animation
    // as we first need to allow for the measurement of the `path`
    // lengths using `.getTotalLength()`
    // setImmediate(() => {
    //   setToggle(true);
    // });
    setTimeout(() => {
      setToggle(true);
    }, 0);
  }, []);

  return (
    <div className="edu-container">
      <div className="edu-text">
        <div className="heading-title">Education</div>
        <div className="subheading">UC Berkeley, 2019-2022</div>
        <p>
          CS61A: Structure and Interpretation of Computer Programs CS61B: Data
          Structures CS61C: Machine Structures CS70: Discrete Mathematics and
          Probability Theory CS170: Efficient Algorithms and Intractable
          Problems * EECS16A: Designing Information Devices and Systems I
          EECS16B: Designing Information Devices and Systems II EECS127:
          Optimization Models in Engineering
        </p>
      </div>
      <div className="bear">
        <Bear toggle={toggle} backgroundColor={"#f0f0f0"} />
      </div>
    </div>
  );
};

export default Education;
