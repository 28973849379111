import "./experience.scss";
import Bear from "./Bear";
import { useState, useEffect } from "react";

// ..
const Experience = () => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    // `setImmediate` is roughly equal to `setTimeout(() => { ... }, 0)
    // Using `setToggle` without `setImmediate` breaks the animation
    // as we first need to allow for the measurement of the `path`
    // lengths using `.getTotalLength()`
    // setImmediate(() => {
    //   setToggle(true);
    // });
    setTimeout(() => {
      setToggle(true);
    }, 0);
  }, []);

  return (
    <div className="exp-container">
      <div className="exp-text">
        <div className="heading-title">Experience</div>
        <section id="cd-timeline" class="cd-container">
          <div className="cd-timeline-block" data-aos="fade-right">
            <div class="cd-timeline-img cd-picture"></div>
            <div class="cd-timeline-content">
              <h2>
                <a href="https://shopcanal.com" className="job-title">
                  Canal
                </a>
              </h2>
              <div class="timeline-content-info">
                <span class="timeline-content-info-title">
                  <i class="fa fa-certificate" aria-hidden="true"></i>
                  Full-Stack Engineer
                </span>
                <span class="timeline-content-info-date">
                  <i class="fa fa-calendar-o" aria-hidden="true"></i>
                  Jan '23 - Present
                </span>
              </div>
              {/* <p>
                Working alongside the designer team implementing the designs,
                also developing custom solutions to address team necessities.
              </p> */}
              <ul class="content-skills">
                <li>Typescript</li>
                <li>Python</li>
                <li>GraphQL</li>
                <li>Django</li>
              </ul>
            </div>
          </div>

          <div class="cd-timeline-block" data-aos="fade-left">
            <div class="cd-timeline-img cd-movie"></div>
            <div class="cd-timeline-content">
              <h2>
                <a href="https://veeva.com" className="job-title">
                  Veeva
                </a>
              </h2>
              <div class="timeline-content-info">
                <span class="timeline-content-info-title">
                  <i class="fa fa-certificate" aria-hidden="true"></i>
                  Software Engineer Intern
                </span>
                <span class="timeline-content-info-date">
                  <i class="fa fa-calendar-o" aria-hidden="true"></i>
                  May '22 - Aug. '22
                </span>
              </div>
              <p>
                • Architected a modular UI component library (similar to
                Storybook.js) capable of generating documentation and displaying
                interactive examples built with Javascript, React, and Webpack.
              </p>
              <p>
                • Replaced existing static documentation used by 100s of
                developers with the interactive component library application
                and reduced implementation time by 20% for all future app
                development.
              </p>
              {/* <span class="cd-date">Jan 18</span> */}
              <ul class="content-skills">
                <li>Javascript</li>
                <li>ReactJS</li>
                <li>Webpack</li>
              </ul>
            </div>
          </div>

          <div class="cd-timeline-block" data-aos="fade-right">
            <div class="cd-timeline-img cd-picture"></div>

            <div class="cd-timeline-content">
              <h2>
                <a href="https://shopcanal.com" className="job-title">
                  Canal
                </a>
              </h2>
              <div class="timeline-content-info">
                <span class="timeline-content-info-title">
                  <i class="fa fa-certificate" aria-hidden="true"></i>
                  Software Engineer Intern
                </span>
                <span class="timeline-content-info-date">
                  <i class="fa fa-calendar-o" aria-hidden="true"></i>
                  Jan '22 - Aug. '22
                </span>
              </div>
              <p>
                Launched analytics in ecommerce startup for clients to track
                metrics like user engagement to decide which products sell
                better and to track all payments from customers through Stripe.
              </p>
              <p>
                • Built full-stack app using TypeScript and React (frontend),
                and GraphQL and Django (backend).
              </p>
              <p>
                • Optimized hiring by building a custom technical screening
                application built into the existing app.
              </p>
              {/* <span class="cd-date">Jan 24</span> */}
              <ul class="content-skills">
                <li>Typescript</li>
                <li>Python</li>
                <li>GraphQL</li>
                <li>Django</li>
              </ul>
            </div>
          </div>

          <div class="cd-timeline-block" data-aos="fade-left">
            <div class="cd-timeline-img cd-location"></div>

            <div class="cd-timeline-content">
              <h2>
                <a href="https://amazon.com" className="job-title">
                  Amazon
                </a>
              </h2>
              <div class="timeline-content-info">
                <span class="timeline-content-info-title">
                  <i class="fa fa-certificate" aria-hidden="true"></i>
                  Software Engineer Intern
                </span>
                <span class="timeline-content-info-date">
                  <i class="fa fa-calendar-o" aria-hidden="true"></i>
                  May '21 - Aug. '21
                </span>
              </div>
              <p>
                • Developed full-stack module using Java, JavaScript, and React
                for Amazon Flex mobile application currently deployed in India
                to add multiple driver transportation modes for deliveries.
              </p>
              <p>
                • Reduced expenses by 30% during peak seasons for third-party
                delivery services and diversified the delivery provider pool to
                drive $1.9M in savings on the Last Mile Delivery team.
              </p>
              <p>
                • Collaborated extensively with overseas teams for the app
                design, development, and deployment.
              </p>
              {/* <span class="cd-date">Feb 14</span> */}
              <ul class="content-skills">
                <li>Java</li>
                <li>Javascript</li>
                <li>ReactJS</li>
              </ul>
            </div>
          </div>

          <div class="cd-timeline-block" data-aos="fade-right">
            <div class="cd-timeline-img cd-location"></div>
            <div class="cd-timeline-content">
              <h2>
                <a href="https://relentlo.com" className="job-title">
                  Relentlo
                </a>
              </h2>
              <div class="timeline-content-info">
                <span class="timeline-content-info-title">
                  <i class="fa fa-certificate" aria-hidden="true"></i>
                  Founding Engineer
                </span>
                <span class="timeline-content-info-date">
                  <i class="fa fa-calendar-o" aria-hidden="true"></i>
                  Nov '20 - Jan. '22
                </span>
              </div>
              <p>
                • Managed the backend team developing cloud services and custom
                SDKs (REST, Unity, Unreal) for displaying in-game ads and
                tracking 1M+ daily impressions in video games.
              </p>
              <p>
                • Created targeted advertising methods in JavaScript to boost
                user engagement with brands by 15%.
              </p>
              <p>
                • Implemented core frontend features of the client and admin
                pages using ReactJS, Node, and Redux.
              </p>
              {/* <span class="cd-date">Feb 18</span> */}
              <ul class="content-skills">
                <li>Javascript</li>
                <li>ReactJS</li>
                <li>C#</li>
                <li>Redux</li>
              </ul>
            </div>
          </div>

          <div class="cd-timeline-block" data-aos="fade-left">
            <div class="cd-timeline-img cd-movie"></div>

            <div class="cd-timeline-content">
              <h2>More Experiences</h2>
              <p>
                To view more details about my experiences view resume below.{" "}
              </p>
              {/* <span class="cd-date">Feb 26</span> */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Experience;
